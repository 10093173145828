<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="待审核列表" name="first" >
      <MUnaudit v-if="isFirst"></MUnaudit>
    </el-tab-pane>
    <el-tab-pane label="已审核列表" name="second">
      <MAudited v-if="isSecond"></MAudited>
    </el-tab-pane>
  </el-tabs>
</template>

<script>

  import MAudited from '@/views/LawyerApplyManageF/myBatchChildrend/MAudited'
  import MUnaudit from '@/views/LawyerApplyManageF/myBatchChildrend/MUnaudit'

  export default {
    components:{MAudited, MUnaudit},
    data() {
      return {
        activeName: 'first',
        isFirst: true,
        isSecond: true,
      };
    },
    methods: {
      handleClick(tab, event) {
        if(tab.name == "first") {
          this.isFirst = true;
          this.isSecond = false;
        }
        else if(tab.name == "second") {
          this.isFirst = false;
          this.isSecond = true;
        }
      }
    }


  }
</script>

<style scoped>

</style>
