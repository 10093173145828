<template>
  <div>
    <div>
      <el-row
        style="padding-bottom: 15px"
        type="flex"
        align="middle"
        justify="space-between"
      >
        <el-col :span="3">
          <b>我的批量申请 - 待审核</b>
        </el-col>
      </el-row>
      <el-table
        ref="multipleTable"
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{ background: '#eee', color: '#333' }"
        @selection-change="handleSelectionChange">
        <!--        <el-table-column-->
        <!--          type="selection"-->
        <!--          width="55">-->
        <!--        </el-table-column>-->

        <el-table-column prop="createTime" label="申报日期" align="center" width="140px"></el-table-column>
        <!--        <el-table-column prop="dname" label="申报律所" align="center" width="100px"></el-table-column>-->
        <el-table-column prop="type" label="申报类型" align="center"></el-table-column>
        <el-table-column prop="detail" label="具体类别" align="center"></el-table-column>
        <!--        <el-table-column prop="content" label="活动内容" align="center"></el-table-column>-->
        <el-table-column prop="score" label="申报学时/时长" align="center" width="140px"></el-table-column>
        <el-table-column prop="num" label="申报人数" align="center" width="100px"></el-table-column>
        <!--        <el-table-column label="状态" align="center">待审核</el-table-column>-->
        <el-table-column label="操作" align="center" width="120px" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-button

                size="mini"
                type="info"
                icon="el-icon-s-custom"
                @click="handleLawyerList(scope.row)">
                律师名单
              </el-button>
            </div>
            <div style="margin-top: 10px">
              <el-button

                size="mini"
                type="warning"
                icon="el-icon-picture-outline"
                @click="handlePhoto(scope.row)">
                查看材料
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!--      分页插件-->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        :page-size="10"
        :total="total"
        style="margin-top: 20px; text-align: right">
      </el-pagination>

    </div>

    <el-dialog
      title="证明材料"
      :visible.sync="photoDialogVisible"

      width="70%"
      class="photoDialog"
    >
      <h2>具体类别</h2>
      <span>{{currentData.detail}}</span>
      <h2>活动内容</h2>
      <span>{{currentData.content}}</span>
      <el-row>

        <h2>图片</h2>
        <div v-for="item in url">
          <el-image
            style="width: 100px; height: 100px; margin-right: 10px; float: left"
            :src="item"
            :preview-src-list="url">
          </el-image>
        </div>
      </el-row>
    </el-dialog>

    <!--    律师名单-->
    <el-dialog
      :visible.sync="lawyerListDialogVisible"
      width="30%"
      class="lawyerDialog"
    >
      <el-table
        ref="multipleTable"
        :data="lawyerList"
        tooltip-effect="dark"
        max-height="400"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          prop="name"
          label="律师姓名"
          width="120">
        </el-table-column>
        <el-table-column
          prop="practiceno"
          label="律师证号"
          show-overflow-tooltip>
        </el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import { request } from '@/network/network'
import {application_status_dict, application_status_options,application_type_dict, application_type_options,
  application_detailPublic_dict, application_detailPublic_options, application_detailTrain_dict, application_detailTrain_options
} from '@/utils/dict'
import  {formatDate} from '@/utils/date'
export default {
  data() {
    return {
      total: 0,
      currentPage: 0,
      searchFirmName: '',
      searchMap: '',
      multipleSelection: [],
      passDialogVisible: false,
      refuseDialogVisible: false,
      passSelectedDialogVisible: false,
      photoDialogVisible:false,
      lawyerListDialogVisible:false,


      url:[],
      urlList:[],


      lawyerList:[],
      currentData: {},
      tableData: [],
      auditBatchId: '',
      refuseInfo: '',
      adminInformation: JSON.parse(sessionStorage.getItem("information"))
    };
  },
  methods: {
    //分页插件绑定事件
    handleCurrentChange(val) {
      this.currentPage = val
      this.getBatchs(this.searchMap, val)
    },
    //搜索按钮
    handleSearch() {
      this.searchMap= this.searchFirmName
      this.getBatchs(this.searchMap, null)
    },
    //重置搜索框
    clearInput() {
      this.searchTitle = ''
      this.searchMap = '',
        this.getBatchs()
    },


    //多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //查看照片
    handlePhoto(row){
      this.currentData =row
      this.url = []

      let photos = JSON.parse(row.photo)
      if(row.photo ==null || row.photo.trim().length === 0){
        request({
          url: '/oss/getUrl',
          method: 'get',
          params: {
            appId: row.id,
          }
        }).then(res => {
          let ossPhotos = JSON.parse(res.data);
          for (const ossPhoto of ossPhotos) {
            // let path = 'https://api.shuobocloud.cn' + photo
            this.url.push(ossPhoto)
          }
        })
      }else{
        for (const photo of photos) {
          let path = 'https://law-1257239481.cos.ap-shanghai.myqcloud.com/static' + photo
          // let path = 'https://api.shuobocloud.cn' + photo
          this.url.push(path)
        }
      }
      this.photoDialogVisible=true
      // let photos = row.photo
      // let a = row.photo.replace('[','')
      // let b = a.replace(']','')
      // let c = b.replace(/"/g,'')
      // let d = c.split(',')

      // for (let i = 0; i < d.length; i++) {
      //   let path = 'http://localhost:8083/static' + d[i]
      //   this.url.push(path)
      // }
    },
    // 查看律师名单
    handleLawyerList(row){
      this.getBatchLawyers(row.bid)
      this.lawyerListDialogVisible = true;

    },

    getBatchs(dname, page) {
      return request({
        url: '/batch/batchs',
        method: 'get',
        params: {
          dname,
          page,
          status: 2,
          did: this.adminInformation.did
        }
      }).then(res => {
        this.total = res.data.total
        let temp = res.data.list
        this.tableData = temp.map(item => {
          item.status = application_status_dict[item.status];
          item.type =  application_type_dict[item.type];
          // item.startTime = formatDate(item.startTime)
          // item.endTime = formatDate(item.endTime)
          // item.createTime = formatDate(item.createTime)
          return item
        })


      })
    },
    getBatchLawyers(bid){
      return request({
        url: '/batch/batchLawyers',
        method: 'get',
        params: {
          bid
        }
      }).then(res => {
        this.lawyerList = res.data

      })

    },
  },

  created() {
    this.getBatchs()
  }

}
</script>

<style scoped>
.input-width {
  width: 80%;
}

</style>
